import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "tooltips-and-toasts"
    }}>{`Tooltips and Toasts`}</h1>
    <p>{`Use tooltips to present small amounts of additional information to the user. Tooltips are similar to
toasts, or microconclusions, which give the user a short confirmation. Follow these guidelines for
both tooltips and toasts:`}</p>
    <ul>
      <li parentName="ul">{`Keep tooltips and toasts brief.`}</li>
      <li parentName="ul">{`Use sentence case.`}</li>
      <li parentName="ul">{`Use the past tense rather than present perfect (was submitted vs. has been submitted). We avoid
the past perfect tense because it is less clear for users and for our translation team.`}</li>
      <li parentName="ul">{`Don't include periods at the end of a tooltip or toast unless it is a complete sentence or a
fragment followed by a complete sentence.`}</li>
    </ul>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Expense submitted`}</li>
        <li parentName="ul">{`Your expense was submitted.`}</li>
        <li parentName="ul">{`Filter data`}</li>
      </ul>
    </Do>
    <Dont mdxType="Dont">
      <ul>
        <li parentName="ul">{`Your expense submission has been submitted`}</li>
        <li parentName="ul">{`Expense Submission Executed.`}</li>
        <li parentName="ul">{`Filter Data.`}</li>
      </ul>
    </Dont>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      